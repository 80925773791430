<template>
  <div style="max-width: 1800px; margin: auto">

    <v-card v-if="categoryChildren.length !== 0" class="subcategories-card" elevation="4">
      <h3 v-if="locale === 'en'">Subcategories</h3>
      <h3 v-else-if="locale === 'de'">Unterkategorien</h3>
      <h3 v-else>Podkategorije</h3>
      <hr style="margin-top: 1px">
      <v-card v-for="child in categoryChildren" :key="child.id" class="subcategory-card" elevation="3" @click="$router.push('/category?id=' + child.id)">
        <div class="item-image">
          <img :src="categoryImagesUrl + child.image" style="width: 100%; height: 100%; object-fit: contain">
        </div>
        <div class="text-center mt-4 pb-4 pl-1 pr-1">
          <h4 v-if="locale === 'en'">{{ child.nameEN }}</h4>
          <h4 v-else-if="locale === 'de'">{{ child.nameDE }}</h4>
          <h4 v-else>{{ child.nameSI }}</h4>
        </div>
      </v-card>
    </v-card>

    <v-card v-if="discountedItems.length !== 0" class="discounted-card" elevation="4">
      <h2 v-if="locale === 'en'">Discounted items in "{{category.nameEN}}" category</h2>
      <h2 v-else-if="locale === 'de'">Reduzierte Artikel in Kategorien: {{ category.nameDE }}</h2>
      <h2 v-else>Akcijski izdelki v kategoriji: {{ category.nameSI }}</h2>
      <hr style="margin-top: 10px">
      <v-card v-for="item in discountedItems" :key="item.id" class="item-card" elevation="3" @click="$router.push('/item?id=' + item.id)">
        <div class="item-image">
          <img :src="itemImagesUrl + item.defaultImage" style="width: 100%; height: 100%; object-fit: contain">
        </div>
        <div class="text-center mt-4">
          <h4 v-if="locale === 'en'">{{ item.nameEN }}</h4>
          <h4 v-else-if="locale === 'de'">{{ item.nameDE }}</h4>
          <h4 v-else>{{ item.nameSI }}</h4>
          <div v-if="item.options.length > 1" style="padding-bottom: 30px">
            <v-chip  class="mt-3 mb-3">
              <b style="font-size: 16px">{{ getPriceRange(item) }}</b>
            </v-chip>
            <br/>
            <small style="color: #808080" v-if="locale === 'de'">* inkl. MwSt</small>
            <small style="color: #808080" v-else-if="locale === 'en'">* VAT included</small>
            <small style="color: #808080" v-else>* DDV vključen</small>
            <br/>
            <v-btn small class=" mt-2" color="primary">
              <span v-if="locale === 'de'"><v-icon small class="mr-1">mdi-information-outline</v-icon>Einzelheiten</span>
              <span v-else-if="locale === 'en'"><v-icon small class="mr-1">mdi-information-outline</v-icon>Details</span>
              <span v-else><v-icon small class="mr-1">mdi-information-outline</v-icon>Podrobnosti</span>
            </v-btn>
          </div>
          <div v-else-if="item.options.length !== 0" style="padding-bottom: 30px">
            <v-chip v-if="item.discount > 0" class="mt-3 mb-3">
              <i style="font-size: 12px; text-decoration: line-through red ">{{ formatPrice(item.options[0].price) }} {{getUnit(item.type)}}</i>
              <b style="font-size: 16px; margin-left: 10px; color: #ff0000">{{ applyDiscount(item.options[0].price, item.discount) }} {{getUnit(item.type)}}</b>
            </v-chip>
            <v-chip v-else class="mt-3 mb-3">
              <b style="font-size: 16px">{{ formatPrice(item.options[0].price) }} {{getUnit(item.type)}}</b>
            </v-chip>
            <br/>
            <small style="color: #808080" v-if="locale === 'de'">* inkl. MwSt</small>
            <small style="color: #808080" v-else-if="locale === 'en'">* VAT included</small>
            <small style="color: #808080" v-else>* DDV vključen</small>
            <br/>
            <v-btn small class="mt-2 mr-2" color="primary">
              <span v-if="locale === 'de'"><v-icon small class="mr-1">mdi-information-outline</v-icon>Einzelheiten</span>
              <span v-else-if="locale === 'en'"><v-icon small class="mr-1">mdi-information-outline</v-icon>Details</span>
              <span v-else><v-icon small class="mr-1">mdi-information-outline</v-icon>Podrobnosti</span>
            </v-btn>
            <!--<v-btn v-if="item.type === 'FINISHED_PRODUCT'" x-small fab class="mt-2" color="primary" @click="addToCart(item, item.options[0])">
              <v-icon  class="">mdi-cart-arrow-down</v-icon>
            </v-btn>-->

          </div>
        </div>
      </v-card>


      <div class="ribbon ribbon-top-right">
        <span v-if="locale === 'en'">DISCOUNTS</span>
        <span v-else-if="locale === 'de'">AKTION</span>
        <span v-else>AKCIJE</span>
      </div>
    </v-card>

    <v-card class="main-card" elevation="4">
      <h2 v-if="locale === 'en'">{{ category.nameEN }}<i style="font-size: 14px; color: #999999; margin-left: 10px;">(All items)</i></h2>
      <h2 v-else-if="locale === 'de'">{{ category.nameDE }}<i style="font-size: 14px; color: #999999; margin-left: 10px;">(Alle Artikel)</i></h2>
      <h2 v-else>{{ category.nameSI }}<i style="font-size: 14px; color: #999999; margin-left: 10px;">(Vsi izdelki)</i></h2>
      <hr style="margin-top: 10px">
      <v-card v-for="item in categoryItems" :key="item.id" class="item-card" elevation="3" @click="$router.push('/item?id=' + item.id)">
        <div class="item-image">
          <img :src="itemImagesUrl + item.defaultImage" style="width: 100%; height: 100%; object-fit: contain">
        </div>
        <div class="text-center mt-4">
          <h4 v-if="locale === 'en'">{{ item.nameEN }}</h4>
          <h4 v-else-if="locale === 'de'">{{ item.nameDE }}</h4>
          <h4 v-else>{{ item.nameSI }}</h4>
          <div v-if="item.options.length > 1" style="padding-bottom: 30px">
            <v-chip  class="mt-3 mb-3">
              <b style="font-size: 16px">{{ getPriceRange(item) }}</b>
            </v-chip>
            <br/>
            <small style="color: #808080" v-if="locale === 'de'">* inkl. MwSt</small>
            <small style="color: #808080" v-else-if="locale === 'en'">* VAT included</small>
            <small style="color: #808080" v-else>* DDV vključen</small>
            <br/>
            <v-btn small class=" mt-2" color="primary">
              <span v-if="locale === 'de'"><v-icon small class="mr-1">mdi-information-outline</v-icon>Einzelheiten</span>
              <span v-else-if="locale === 'en'"><v-icon small class="mr-1">mdi-information-outline</v-icon>Details</span>
              <span v-else><v-icon small class="mr-1">mdi-information-outline</v-icon>Podrobnosti</span>
            </v-btn>
          </div>
          <div v-else-if="item.options.length !== 0" style="padding-bottom: 30px">
            <v-chip v-if="item.discount > 0" class="mt-3 mb-3">
              <i style="font-size: 12px; text-decoration: line-through red ">{{ formatPrice(item.options[0].price) }} {{getUnit(item.type)}}</i>
              <b style="font-size: 16px; margin-left: 10px; color: #ff0000">{{ applyDiscount(item.options[0].price, item.discount) }} {{getUnit(item.type)}}</b>
            </v-chip>
            <v-chip v-else class="mt-3 mb-3">
              <b style="font-size: 16px">{{ formatPrice(item.options[0].price) }} {{getUnit(item.type)}}</b>
            </v-chip>
            <br/>
            <small style="color: #808080" v-if="locale === 'de'">* inkl. MwSt</small>
            <small style="color: #808080" v-else-if="locale === 'en'">* VAT included</small>
            <small style="color: #808080" v-else>* DDV vključen</small>
            <br/>
            <v-btn small class="mt-2 mr-2" color="primary">
              <span v-if="locale === 'de'"><v-icon small class="mr-1">mdi-information-outline</v-icon>Einzelheiten</span>
              <span v-else-if="locale === 'en'"><v-icon small class="mr-1">mdi-information-outline</v-icon>Details</span>
              <span v-else><v-icon small class="mr-1">mdi-information-outline</v-icon>Podrobnosti</span>
            </v-btn>
            <!--<v-btn v-if="item.type === 'FINISHED_PRODUCT'" x-small fab class="mt-2" color="primary" @click="addToCart(item, item.options[0])">
              <v-icon  class="">mdi-cart-arrow-down</v-icon>
            </v-btn>-->

          </div>
          <div v-if="item.discount > 0" class="ribbon ribbon-top-left">
            <span v-if="locale === 'en'">DISC. -{{ item.discount }}%</span>
            <span v-else-if="locale === 'de'">AKTION -{{ item.discount }}%</span>
            <span v-else>AKCIJA -{{ item.discount }}%</span>
          </div>

        </div>
      </v-card>
    </v-card>



    <v-expand-transition>
    <v-snackbar
        v-model="snackbar"
        :timeout="4000"
        :height="80"
        :right="true"
        :color="snackbarColor"
        :elevation="24"
    >
      {{ displayMessage }}

    </v-snackbar>
    </v-expand-transition>
  </div>
</template>

<script>

import CategoryService from "@/services/categoty.service"
import ItemsService from "@/services/items.service"
import EndpointsService from "@/services/endpoints.service"
import CartService from "@/services/cart.service"

export default {
  name: "category",
  title: "ZAVESE.EU",
  data() {
    return {
      categoryId: "",

      loadingCategory: false,
      categoryError: false,
      category: [],

      loadingItems: false,
      itemsError: false,
      categoryItems: [],

      categoryChildren: [],

      snackbar: false,
      snackbarColor: "#FFB300",
      displayMessage: "",
      locale: "",
    };
  },
  computed: {
    itemImagesUrl() {
      return EndpointsService.getApiV1Endpoint() + 'items/image/';
    },
    categoryImagesUrl() {
      return EndpointsService.getApiV1Endpoint() + 'categories/image/';
    },
    discountedItems() {
      var discountedItems = [];
      for (var i = 0; i<this.categoryItems.length; i++){
        if (this.categoryItems[i].discount > 0){
          discountedItems.push(this.categoryItems[i]);
        }
      }
      return discountedItems
    }
  },
  mounted(){
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    if( urlParams.has('id') ){
      this.categoryId = urlParams.get('id');
    }
    else
      this.$router.push("/home")

    this.getCategory();
    this.getItems();
    if(localStorage.getItem("zavese-locale") === null){
      localStorage.setItem("zavese-locale", "si")
    }
    this.locale = localStorage.getItem("zavese-locale")
  },
  methods: {
    getCategory(){
      this.loadingCategory = true;
      this.categoryError = false;
      CategoryService.getCategory(this.categoryId).then(
          response => {
            this.category = response.data
            this.loadingCategory = false;
          },
          error => {
            this.categoryError = true;
            this.loadingCategory = false;
            console.log("ERROR GETTING CATEGORIES:")
            console.log(error.response.data)
          }
      )
      this.getCategoryChildren();
    },

    getCategoryChildren(){
      CategoryService.getAllChildren(this.categoryId).then(
          response => {
            this.categoryChildren = response.data
          },
          error => {
            console.log("ERROR GETTING CATEGORIES:")
            console.log(error.response.data)
          }
      )
    },

    getItems(){
      this.loadingItems = true;
      this.itemsError = false;
      ItemsService.getForCategory(this.categoryId).then(
          response => {
            this.categoryItems = response.data
            this.loadingItems = false;
          },
          error => {
            this.itemsError = true;
            this.loadingItems = false;
            console.log("ERROR GETTING ITEMS:")
            console.log(error.response.data)
          }
      )
    },

    getPriceRange(item){
      if(item.options.length !== 0){
        var minPrice = 1000000000;
        var maxPrice = 0;
        for (var i = 0; i<item.options.length; i++){
          if(item.options[i].price > maxPrice)
            maxPrice = item.options[i].price
          if(item.options[i].price < minPrice)
            minPrice = item.options[i].price
        }
        return this.formatPrice(minPrice) + this.getUnit(item.type) + " - " + this.formatPrice(maxPrice) + this.getUnit(item.type)
      }
      else return "Error 021";
    },

    getUnit(type){
      if(type === "BLINDS")
        return "€/m²"
      else if (type === "FABRIC")
        return "€/m"
      else
        return "€"
    },

    formatPrice(number){
      return number.toFixed(2)
    },

    applyDiscount(price, discount){
      return this.formatPrice(parseFloat(price) * (1.0 - (parseFloat(discount) / 100.0)))
    },

    addToCart(item, option){
      CartService.addToLocalCart(item, 1, option);
      if(this.locale === "en") this.displayMessage = "Item successfully added to your cart!"
      else if(this.locale === "else") this.displayMessage = "Artikel erfolgreich zu Ihrem Warenkorb hinzugefügt!"
      else this.displayMessage = "Izdelek uspešno dodan v vašo košarico!"
      this.snackbar = true
    }
  },
  watch:{
    $route (to, from){
      console.log("Changing category from " + from + " to " + to)
      this.$router.go();
    }
  }
}
</script>

<style scoped>
  @import "../css/ribbon.css";

  hr {
    color: #E8673A;
  }

  .subcategories-card{
    padding: 30px;
    padding-bottom: 60px;
    margin: 15px;
    background-color: #f3f3f3;
    max-width: 1800px;
  }

  .discounted-card{
    padding: 30px;
    padding-bottom: 60px;
    margin: 15px;
    background-color: #f3f3f3;
    max-width: 1800px;
  }

  .main-card{
    padding: 30px;
    padding-bottom: 60px;
    margin: 15px;
    background-color: #f3f3f3;
    max-width: 1800px;
  }
  .item-card {
    display: inline-block;
    width: 14%;
    margin-left: 2.3%;
    margin-top: 50px;
    background-color: #f5f5f5;
  }

  .item-image {
    border: solid 1px #d5d5d5;
    border-radius: 10px;
    margin: auto;
    width: 80%;
    aspect-ratio : 1 / 1;
    margin-top: 20px;
  }


  .subcategory-card {
    display: inline-block;
    width: 14%;
    margin-left: 2.3%;
    margin-top: 50px;
    background-color: #f5f5f5;
  }


  @media only screen and (max-width: 1800px){
    .item-card {
      width: 22.5%;
      margin-left: 2%;
    }
  }

  @media only screen and (max-width: 1450px){
    .item-card {
      width: 30%;
      margin-left: 2%;
    }
  }

  @media only screen and (max-width: 1100px){
    .item-card {
      width: 45%;
      margin-left: 3%;
    }
  }

  @media only screen and (max-width: 950px){
    .subcategory-card {
      width: 20%;
      margin-left: 4%;
    }
  }

  @media only screen and (max-width: 540px){
    .item-card {
      width: 100%;
      margin-left: 0;
    }
    .subcategory-card {
      width: 45%;
      margin-left: 4%;
    }
  }
</style>
