import axios from 'axios';
import EndpointService from "./endpoints.service"


const API_URL = EndpointService.getApiV1Endpoint();

class ItemsService {

    getOne(itemId) {
        return axios.get(API_URL + "items/" + itemId, { headers: {"Content-Type": "application/json; charset=UTF-8"} })
    }

    getForCategory(categoryId) {
        return axios.get(API_URL + "items/get-from-category/" + categoryId, { headers: {"Content-Type": "application/json; charset=UTF-8"} })
    }

    getSearchItems(searchTerm){
        if(searchTerm === "")
            return axios.get(API_URL + "items/search/<<<promoted-items>>>", { headers: {"Content-Type": "application/json; charset=UTF-8"} })
        else
            return axios.get(API_URL + "items/search/" + searchTerm, { headers: {"Content-Type": "application/json; charset=UTF-8"} })
    }
}

export default new ItemsService();
